.LoaderWrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}

.Loader {
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/img/loader.svg');
  animation: rotate 1s steps(8) infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}